exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-area-js": () => import("./../../../src/pages/area.js" /* webpackChunkName: "component---src-pages-area-js" */),
  "component---src-pages-heritage-js": () => import("./../../../src/pages/heritage.js" /* webpackChunkName: "component---src-pages-heritage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-processions-js": () => import("./../../../src/pages/processions.js" /* webpackChunkName: "component---src-pages-processions-js" */),
  "component---src-templates-walk-js": () => import("./../../../src/templates/walk.js" /* webpackChunkName: "component---src-templates-walk-js" */)
}

